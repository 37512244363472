import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import './index.less'
import './mobile.less'

import Header from '@/components/Headers'
import Footer from '@/components/Footers'

import {getUsingClassName} from '@/common';

const defaultProps = {
  className: '',
  isShowFooterTop: true,
  isDetailPage: false,
  detailPageTitle: '',
  onHeaderLeftDetailPageClick: undefined,
  headerLogoutIsGoHome: false,
  pathnameChangeIsRestoreScrollState: true,
  isShowHeaderRight: true,
  isShowSearch: true,
  showFooter: true,
};

const Layout = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'layout-mobile-container' : 'layout-container'

  const restoreScrollState = (top = 0) => {
    window.scroll({
      top: top,
      left: 0,
      // behavior: 'smooth'
    });
  }
  
  const scrollIntoView = (el, options) => {
    el.scrollIntoView(options)
  }
  
  useEffect(() => {
    options.pathnameChangeIsRestoreScrollState && restoreScrollState()
  }, [location.pathname])
  
  useImperativeHandle(ref, () => ({
    restoreScrollState,
    scrollIntoView
  }))
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  return (
    <div className={className}>
      <Header
        className={'layout-header-container'}
        logoutIsGoHome={options.headerLogoutIsGoHome}
        isDetailPage={options.isDetailPage}
        detailPageTitle={options.detailPageTitle}
        onLeftDetailPageClick={options.onHeaderLeftDetailPageClick}
        isShowHeaderRight={options.isShowHeaderRight}
        isShowSearch={options.isShowSearch}
      />
      <div className={'app-content-container'}>
        {options.children}
      </div>
      {options.showFooter && <Footer isShowFooterTop={options.isShowFooterTop} />}
    </div>
  )
})

export default Layout
