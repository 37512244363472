import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd'
import {DownloadOutlined} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import noImage from '@/assets/img/icon/noImage.png'

import {downloadFile, getUsingClassName} from '@/common';
import {generateQrcode} from '@/api/req-api';


const defaultProps = {
  className: '',
};

export default function MyQrcode(props) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-qrcode-mobile-container' : 'my-qrcode-container'
  const userInfo = commonState.userInfo || {}
  const customerNum = userInfo.CustomerNum

  const [file, setFile] = useState('')
  
  useEffect(() => {
    if (!customerNum) {
      return
    }
    generateQrcode(customerNum).then(res => {
      const file = window.URL.createObjectURL(res.data);
      setFile(file)
    })
  }, [customerNum])
  
  const handleDownloadQrcodeClick = () => {
    downloadFile(file, 'QR', true)
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  const btnClassName = getUsingClassName(
    ['my-qrcode-btn-container'],
    ['my-qrcode-btn-disabled-container'],
    !file
  )

  return (
    <div className={className}>
      <div className={'my-qrcode-img-container'}>
        <img src={file ? file : noImage} alt="" />
      </div>
      <Button
        disabled={!file}
        className={btnClassName} 
        icon={<DownloadOutlined className={'my-qrcode-btn-icon'} />}
        onClick={handleDownloadQrcodeClick}>
        {t('Download QR')}
      </Button>
    </div>
  )
}
