import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from 'lodash'
import {message} from 'antd';

import i18next from '@/i18next'
import {getSubCustomer, addSubCustomer, deactiveSubCustomer, activeSubCustomer} from '@/api/req-api';

export const fetchSubCustomer = createAsyncThunk(
    'subordinate/getSubCustomer',
    async (_, {rejectWithValue}) => {
      try {
        const response = await getSubCustomer();
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const addSubCustomerItem = createAsyncThunk(
    'subordinate/addSubCustomer',
    async ({subCustomer}, {rejectWithValue, dispatch}) => {
      try {
        const response = await addSubCustomer(subCustomer);
        dispatch(fetchSubCustomer())
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const deactiveSubCustomerItem = createAsyncThunk(
    'subordinate/deactiveSubCustomer',
    async ({subMobileId}, {rejectWithValue, dispatch}) => {
      try {
        const response = await deactiveSubCustomer(subMobileId);
        dispatch(fetchSubCustomer())
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const activeSubCustomerItem = createAsyncThunk(
    'subordinate/activeSubCustomer',
    async ({subMobileId}, {rejectWithValue, dispatch}) => {
        try {
            const response = await activeSubCustomer(subMobileId);
            dispatch(fetchSubCustomer())
            return get(response, 'data');
        } catch (e) {
            return rejectWithValue(e);
        }
    },
)

const subordinateSlice = createSlice({
  name: 'subordinate',
  initialState: {
    subCustomer: []
  },
  extraReducers: builder => {
    builder.addCase(fetchSubCustomer.fulfilled, (state, {payload}) => {
      state.subCustomer = payload.filter(item => !item.self).map(item => {
        return {
          ...item,
          name: item.FirstName || item.LastName
        }
      })
    })
    builder.addCase(addSubCustomerItem.fulfilled, (state, {payload}) => {
      message.success(i18next.t('Added successfully'))
    })
    builder.addCase(deactiveSubCustomerItem.fulfilled, (state, {payload}) => {
      message.success(i18next.t('Deactive successfully'))
    })
    builder.addCase(activeSubCustomerItem.fulfilled, (state, {payload}) => {
      message.success(i18next.t('Active successfully'))
    })
  },
});

export default subordinateSlice.reducer;
