import React, {useState} from 'react'
import {useSelector} from 'react-redux';

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';

import arrowLeftIcon from '@/assets/img/icon/arrowLeft.png'
import arrowRightIcon from '@/assets/img/icon/arrowRight.png'

const defaultProps = {
  data: [],
  onSlideChange: () => {},
  onSwiper: () => {},
  renderSwiperSlideContent: () => {},
  spaceBetween: undefined,
  slidesPerView: undefined,
  className: '',
  isShowPagination: false,
  isShowNavigation: true,
  prevClassName: '',
  nextClassName: '',
  swiperClassName: '',
};
export default function OSwiper(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'o-swiper-mobile-container' : 'o-swiper-container'
  
  const [swiperRef, setSwiperRef] = useState(null)
  const handleOnSlideChange = () => {}
  const handleOnSwiper = swiper => {
    setSwiperRef(swiper)
  }
  const handlePrev = () => {
    swiperRef.slidePrev()
  }
  const handleNext = () => {
    swiperRef.slideNext()
  }

  const swiperOtherProps = {}
  const swiperModules = []
  if (options.isShowPagination) {
    swiperModules.push(Pagination);
    swiperOtherProps['pagination'] = { clickable: true }
  }
  const dataLength = options.data?.length || 0
  const isShowPrev = options.isShowNavigation && dataLength > 1
  const isShowNext = options.isShowNavigation && dataLength > 1

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  const swiperClassName = getUsingClassName(
    ['o-swiper-swiper'],
    [options.swiperClassName]
  )
  const pervClassName = getUsingClassName(
    ['o-swiper-prev-container'],
    [options.prevClassName]
  )
  const nextClassName = getUsingClassName(
    ['o-swiper-next-container'],
    [options.nextClassName]
  )
  
  const renderSwiperSlide = (item, index) => <SwiperSlide key={`swiper_slide_${index}`}>
    {options.renderSwiperSlideContent(item, index)}
  </SwiperSlide>
  
  return (
    <div className={className}>
      {
        isShowPrev && <div
          className={pervClassName}
          onClick={handlePrev}>
          <img src={arrowLeftIcon} alt=""/>
        </div>
      }
      <Swiper
        className={swiperClassName}
        modules={swiperModules}
        spaceBetween={options.spaceBetween}
        slidesPerView={options.slidesPerView}
        onSlideChange={handleOnSlideChange}
        onSwiper={handleOnSwiper}
        {...swiperOtherProps}>
        {options.data.map((item, index) => renderSwiperSlide(item, index))}
      </Swiper>
      {
        isShowNext && <div
          className={nextClassName}
          onClick={handleNext}>
          <img src={arrowRightIcon} alt=""/>
        </div>
      }
    </div>
  )
}
