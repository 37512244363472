import {configureStore} from '@reduxjs/toolkit';
import commonReducer from './modules/common.slice';
import cartReducer from './modules/cart.slice';
import addressReducer from './modules/address.slice';
import subordinateReducer from './modules/subordinate.slice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    cart: cartReducer,
    address: addressReducer,
    subordinate: subordinateReducer,
  },
});
