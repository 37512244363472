import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {Modal} from 'antd'

import './index.less'
import './mobile.less'

import OSuccess from '@/components/OSuccess'
import {getUsingClassName} from '@/common';

const defaultProps = {
  className: '',
};

const SignupSuccess = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'signup-success-mobile-container' : 'signup-success-container'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => { 
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
    showModal
  }))
  
  return (
    <Modal
      className={className}
      maskClosable={false}
      keyboard={false}
      closeIcon={null}
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      <OSuccess
        title1={t('Congratulations! Your sign-up was successful.')}
        title2={t('Thank you for joining our community.')}
        des={t('We have received your information, and our team will review and approve your request shortly.')}
        tip={t('Please keep an eye on your email for further updates. We appreciate your patience and look forward to having you as a valued member. If you have any questions or need assistance, please don\'t hesitate to reach out to our support team.')}
        onClose={handleCancel}
      />
    </Modal>
  )
})

export default SignupSuccess
