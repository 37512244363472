import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {useSelector} from 'react-redux';
import {Upload} from 'antd';

import './index.less'
import './mobile.less'

import {getUsingClassName, getBase64Img} from '@/common';
import {uploadCustomerPhoto} from '@/api/req-api'

const defaultProps = {
  className: '',
  onSelectSuccess: () => {},
  onUploadSuccess: () => {},
};

const OUpload = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'o-upload-mobile-container' : 'o-upload-container'
  
  const [fileList, setFileList] = useState([])

  const beforeUpload = file => {
    setFileList([file])
    getBase64Img(file, filePath => {
      options.onSelectSuccess(filePath)
    })
    return false
  }
  
  const startUpload = () => {
    const formData = new FormData();
    if (!fileList.length) {
      console.log('Please select a file first')
      return
    }
    formData.append('File', fileList[0]);
    uploadCustomerPhoto(formData).then(res => {
      options.onUploadSuccess(res.data)
    })
  }
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
    startUpload
  }))
  
  return (
    <Upload
      className={className}
      name={'file'}
      accept="image/*"
      beforeUpload={beforeUpload}
      maxCount={1}
      showUploadList={false}
      listType={'picture-circle'}>
      {options.children}
    </Upload>
  )
})

export default OUpload
