import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import {cloneDeep, kebabCase} from 'lodash'
import {message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import OSwiper from 'src/components/OSwiper'
import ProductItem from '@/components/ProductItem'

import bannerImg from '@/assets/img/home/banner.png'

import {
  getUsingClassName,
  checkViewableLanguages,
  checkLogin,
  getFormatProductListByCartList,
  getProductItemBtnType
} from '@/common';
import {
  getBillboard,
  getCategory2,
  addProductToFavoriteList,
  removeProductFromFavoriteList
} from '@/api/req-api'
import {addProductToCart, removeCartItem, updateCartItemQuantity} from '@/store/modules/cart.slice';

export default function Home() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const cartState = useSelector(state => state.cart)
  const zipcode = commonState.zipcode
  const isLogged = commonState.isLogged
  const rootClassName = commonState.isMobile ? 'home-mobile-container' : 'home-container'
  const {orderCartProductList} = cartState
  
  const [category2, setCategory2] = useState([])
  const useCategory2 = useMemo(() => {
    return category2.map(item => {
      return {
        ...item,
        Products: getFormatProductListByCartList(item.Products, orderCartProductList)
      }
    })
  }, [category2, orderCartProductList])
  const buttonCategoriesList = useMemo(
    () => useCategory2.filter(item => item.IsButton),
    [useCategory2]
  )
  const enBtnCategoriesList = useMemo(
    () => buttonCategoriesList.filter(item => checkViewableLanguages(item.ViewableLanguages, 'en')).splice(0, 4),
    [buttonCategoriesList]
  )
  const zhBtnCategoriesList = useMemo(
    () => buttonCategoriesList.filter(item => checkViewableLanguages(item.ViewableLanguages, 'zh')).splice(0, 4),
    [buttonCategoriesList]
  )
  const notBtnCategoriesList = useMemo(
    () => useCategory2.filter(item => !item.IsButton).map(item => {
      return {
        ...item,
        Products: item.Products
      }
    }),
    [useCategory2]
  )
  const firstNotBtnCategories = useMemo(
    () => notBtnCategoriesList[0] || {},
    [notBtnCategoriesList]
  )
  const firstNotBtnCategoriesProducts = useMemo(
    () => firstNotBtnCategories?.Products || [],
    [firstNotBtnCategories]
  )
  const notFirstNotBtnCategories = useMemo(
    () => notBtnCategoriesList.splice(1),
    [notBtnCategoriesList]
  )

  const [billboard, setBillboard] = useState([])
  
  useEffect(() => {
    getCategory2().then(res => {
      setCategory2(res.data)
    })
  }, [zipcode])
  useEffect(() => {
    getBillboard().then(res => {
      const formatData = res.data.map(item => {
        return {
          ...item,
          img: item.Image
        }
      })
      setBillboard(formatData)
    })
  }, [])

  const handleProductItemClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    navigate(path);
  }
  const handleProductItemBtnClick = item => {
    dispatch(addProductToCart({item}))
  }
  
  const handleBeforeAddToCart = item => {
    checkLogin(
      navigate,
      isLogged,
      location,
      () => handleProductItemBtnClick(item)
    )
  }

  const handleChangeFavoriteStatus = item => {
    const isFavorite = item.IsFavorite
    const msg = isFavorite ? t('Cancel favorite successfully') : t('Add favorite successfully')
    const fun = isFavorite ? removeProductFromFavoriteList : addProductToFavoriteList
    const newCategory2 = cloneDeep(category2)
    let productsIdx = -1
    const newCategory2Idx = newCategory2.findIndex(it => {
      const idx = it.Products.findIndex(ite => ite.Product_id === item.Product_id)
      if (idx >= 0) {
        productsIdx = idx
        return true
      }
      return false
    })
    if (newCategory2Idx < 0 || productsIdx < 0) {
      return
    }
    fun(item).then(() => {
      message.success(msg)
      newCategory2[newCategory2Idx].Products.splice(productsIdx, 1, {...item, IsFavorite: !isFavorite})
      setCategory2(newCategory2)
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = item => {
    handleBeforeChangeFavoriteStatus(item, () => handleChangeFavoriteStatus(item))
  }
  
  const handleMinBtnClick = (item, qty) => {
    const newQty = qty - 1
    if (newQty < 1) {
      return dispatch(removeCartItem({item}))
    }
    dispatch(updateCartItemQuantity({item, quantity: newQty}))
  }
  
  const renderSwiperSlideContent = (item, index) => {
    return <ProductItem
      onProductItemClick={() => handleProductItemClick(item)}
      img={item.Thumbnail}
      packageType={item.MeasureUnit}
      des={item.Name}
      price={item.Price}
      isShowPrice={isLogged}
      isFormatPriceToMoney={true}
      onBtnClick={() => handleBeforeAddToCart(item)}
      isFavorited={item.IsFavorite}
      isShowFavorite={true}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      quantity={item.Quantity}
      btnType={getProductItemBtnType(item.Quantity)}
      onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
      onPlusBtnClick={() => handleProductItemBtnClick(item)}
      isPromotion={item.IsPromotion}
      isOverSaleable={item.IsOverSaleable}
      stock={item.StockLevel}
    />
  }
  const renderProductList = (productList) => <OSwiper
    data={productList} 
    renderSwiperSlideContent={renderSwiperSlideContent}
    slidesPerView={commonState.isMobile ? 2 : 4}
  />
  const renderProductGroup = (productList, title) => {
    const className = getUsingClassName(
      ['home-product-group-container'],
      [`home-product-group-${kebabCase(title)}`]
    )
    return <div className={className} key={title}>
      {productList.length > 0 && <div className={'home-product-group-title'}>{title}</div>}
      <div className={'home-product-group-list'}>
        {renderProductList(productList)}
      </div>
    </div>
  }
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'home-header-container'}
          des={'olong ' + t('restaurant supplies')}
          title={t('WE SPECIALIZE IN PROVIDING HUNDREDS OF HIGH-QUALITY GOODS SUCH AS SEAFOOD, VEGETABLES, MEAT, DRY GOODS, AND MORE')}
          bannerList={billboard}
        />
        {renderProductGroup(firstNotBtnCategoriesProducts, firstNotBtnCategories.Name)}
        <div className={'home-whats-news-container'}>
          <img src={bannerImg} alt=""/>
        </div>
        <div className={'home-img-container'}>
          {
            enBtnCategoriesList.map((item, index) => <div
              className={'home-img-item-container'}
              key={`en_btn_categories_list_${index}`}>
              <img src={item.Image} alt=""/>
            </div>)
          }
        </div>
        {
          notFirstNotBtnCategories.map((item, index) => renderProductGroup(item.Products, item.Name))
        }
      </div>
    </Layout>
  )
}
