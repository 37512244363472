import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from 'lodash'
import {message} from 'antd';

import i18next from '@/i18next'
import {
  addAddress,
  deleteAddress,
  editAddress,
  getAddressList,
  getDefaultAddress
} from '@/api/req-api'

export const fetchAddressList = createAsyncThunk(
    'address/addressList',
    async (_, {rejectWithValue}) => {
      try {
        const response = await getAddressList();
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const fetchDefaultAddress = createAsyncThunk(
    'address/defaultAddress',
    async (_, {rejectWithValue}) => {
      try {
        const response = await getDefaultAddress();
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const fetchAddressListAndDefaultAddress = createAsyncThunk(
    'address/addressListAndDefaultAddress',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const p1 = dispatch(fetchAddressList())
        const p2 = dispatch(fetchDefaultAddress())
        const response = await Promise.all([p1, p2])
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const addAddressToAddressList = createAsyncThunk(
    'address/addAddress',
    async ({address}, {rejectWithValue, dispatch}) => {
      try {
        const response = await addAddress(address);
        dispatch(fetchAddressListAndDefaultAddress())
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)
export const removeAddressItem = createAsyncThunk(
  'address/deleteAddress',
  async ({customerDetailId}, {rejectWithValue, dispatch}) => {
    try {
      const response = await deleteAddress(customerDetailId);
      dispatch(fetchAddressListAndDefaultAddress())
      return get(response, 'data');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
)
export const updateAddressItem = createAsyncThunk(
    'cart/editAddress',
    async ({address, customerDetailId}, {rejectWithValue, dispatch}) => {
      try {
        const response = await editAddress(address, customerDetailId);
        dispatch(fetchAddressListAndDefaultAddress())
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)


const addressSlice = createSlice({
  name: 'address',
  initialState: {
    customerDetail: [],
    defaultAddress: {}
  },
  extraReducers: builder => {
    builder.addCase(fetchAddressList.fulfilled, (state, {payload}) => {
      state.customerDetail = payload.Customer_Detail
    })
    builder.addCase(fetchDefaultAddress.fulfilled, (state, {payload}) => {
      state.defaultAddress = {
        ...payload,
        IsDefault: true,
      }
    })
    builder.addCase(addAddressToAddressList.fulfilled, (state, {payload}) => {
      message.success(i18next.t('Added successfully'))
    })
    builder.addCase(updateAddressItem.fulfilled, (state, {payload}) => {
      message.success(i18next.t('Edited successfully'))
    })
    builder.addCase(removeAddressItem.fulfilled, (state, {payload}) => {
      message.success(i18next.t('Removed successfully'))
    })
  },
});

export default addressSlice.reducer;
