import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {Form, Input, Select} from 'antd'

import './index.less'
import './mobile.less'

import {formRulesPhoneNumValidator, getUsingClassName} from '@/common';


const {Option} = Select;

const defaultProps = {
  className: '',
  emailRequired: true,
  isShowBaseInfo: true,
  isShowCompanyInfo: true,
  isShowAddressInfo: true,
  isShowPassword: false,
  isRequiredBusinessType: false,
  isShowEmail: true,
  isChangePassword: false,
  onFormFinish: () => {
  },
  initialValues: undefined,
  disabledInputNameList: [],
};

const UserInfoForm = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'user-info-form-mobile-container' : 'user-info-form-container'

  const {disabledInputNameList} = options
  
  const formRef = useRef(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordEdit, setNewPasswordEdit] = useState(false);

  const businessTypeList = [
    {
      label: 'Individual',
      value: 'Individual',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
    {
      label: 'Restaurant',
      value: 'Restaurant',
    },
    {
      label: 'Manufacturer',
      value: 'Manufacturer',
    },
    {
      label: 'Wholesaler / Distributor',
      value: 'Wholesaler / Distributor',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ]

  const submit = () => formRef.current?.submit()
  const resetFields = () => formRef.current?.resetFields()

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  useImperativeHandle(ref, () => ({
    submit,
    resetFields,
  }))

  return (
    <Form
      className={className}
      ref={formRef}
      layout={'vertical'}
      requiredMark={false}
      onFinish={options.onFormFinish}
      autoComplete="off"
      initialValues={options.initialValues}>
      {
        options.isShowBaseInfo &&
        <>
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('Please enter your name'),
              },
            ]}>
            <Input
              disabled={disabledInputNameList.includes('name')}
              placeholder={t('Enter your name')}
            />
          </Form.Item>
          {options.isShowEmail && <Form.Item
            label={t('Email Address')}
            name="emailAddress"
            rules={[
              {
                required: options.emailRequired,
                message: t('Please enter your email address'),
              },
            ]}>
            <Input
              disabled={(disabledInputNameList.includes('emailAddress'))}
              placeholder={t('Enter your email address')}
            />
          </Form.Item>}
          <Form.Item
            label={t('Phone number')}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: t('Please enter your phone number'),
              },
              {
                validator: (_, value) => formRulesPhoneNumValidator(_, value, t('The enter is not valid Phone number')),
              }
            ]}>
            <Input
              disabled={disabledInputNameList.includes('phoneNumber')}
              placeholder={t('Enter your phone number')}
            />
          </Form.Item>
        </>
      }
      {
        options.isShowCompanyInfo &&
        <>
          <Form.Item
            label={t('Company')}
            name="company"
            rules={[
              {
                required: true,
                message: t('Please enter your company name'),
              },
            ]}>
            <Input
              disabled={disabledInputNameList.includes('company')}
              placeholder={t('Enter your company name')}
            />
          </Form.Item>
          <Form.Item
            label={t('Company Phone')}
            name="companyPhone"
            rules={[
              {
                validator: (_, value) => formRulesPhoneNumValidator(_, value, t('The enter is not valid Company Phone')),
              }
            ]}>
            <Input
              disabled={disabledInputNameList.includes('companyPhone')}
              placeholder={t('Enter your company phone')}
            />
          </Form.Item>
          <Form.Item
            label={t('Business Type')}
            name="businessType"
            id={'user-info-form-monthly-volume-root'}
            rules={[
              {
                required: options.isRequiredBusinessType,
                message: t('Please select company type'),
              },
            ]}>
            <Select
              disabled={disabledInputNameList.includes('businessType')}
              placeholder={t('Enter your company business type')}
              getPopupContainer={() => document.getElementById('user-info-form-monthly-volume-root')}>
              {
                businessTypeList.map((item, index) => {
                  return <Option
                    value={item.value}
                    key={'business_type_' + index}>
                    {t(item.label)}
                  </Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={'EIN Number'}
            name="einNumber">
            <Input
              disabled={disabledInputNameList.includes('einNumber')}
              placeholder={t('Enter your company EIN number')}
            />
          </Form.Item>
        </>
      }
      {
        options.isShowAddressInfo &&
        <>
          <Form.Item
            label={t('Address')}
            name="address"
            rules={[
              {
                required: true,
                message: t('Please enter your address'),
              },
            ]}>
            <Input
              disabled={disabledInputNameList.includes('address')}
              placeholder={t('Enter your address')}
            />
          </Form.Item>
          <Form.Item
            label={t('City')}
            name="city"
            rules={[
              {
                required: true,
                message: t('Please enter your address city'),
              },
            ]}>
            <Input
              disabled={disabledInputNameList.includes('city')}
              placeholder={t('Enter your address city')}
            />
          </Form.Item>
          <Form.Item
            label={t('State')}
            name="state"
            rules={[
              {
                required: true,
                message: t('Please enter your address state'),
              },
            ]}>
            <Input
              disabled={disabledInputNameList.includes('state')}
              placeholder={t('Enter your address state')}
            />
          </Form.Item>
          <Form.Item
            label={t('Zip Code')}
            name="zipCode"
            rules={[
              {
                required: true,
                message: t('Please enter your address zipcode'),
              },
            ]}>
            <Input
              disabled={disabledInputNameList.includes('zipCode')}
              placeholder={t('Enter your address zipcode')}
            />
          </Form.Item>
        </>
      }
      {
        options.isShowPassword &&
        <>
          <div className={'user-info-form-password-container'}>
            <Form.Item
              label={t('Password')}
              name="password"
              rules={[
                {
                  required: options.isChangePassword ? newPasswordEdit : true,
                  message: t('Please enter your password'),
                },
              ]}>
              <Input.Password
                disabled={options.isChangePassword && !newPasswordEdit || (!options.isChangePassword && newPasswordEdit)}
                placeholder={options.isChangePassword && !newPasswordEdit?'******':t('Enter your password')}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                autoComplete={'new-password'}
                iconRender={() => null}
              />
            </Form.Item>
            <div>
            {options.isChangePassword ? <div className={newPasswordEdit?'user-info-form-show-hide-new-password':'user-info-form-show-hide-new-password2'}
              onClick={() => setNewPasswordEdit(prevState=> !prevState)}>
              {t('Change Password')}
            </div> : <></>}
            {(!options.isChangePassword || options.isChangePassword && newPasswordEdit) ? <div
              className={'user-info-form-show-hide-password'}
              onClick={() => setPasswordVisible(prevState=> !prevState)}>
              {passwordVisible ? t('Hide') : t('Show')} {t('Password')}
            </div> : <></> }
            </div>
            {newPasswordEdit && 
            <>
            <Form.Item
              label={t('New Password')}
              name="new_password"
              rules={[
                {
                  required: newPasswordEdit,
                  message: t('Please enter your new password'),
                },
              ]}>
              <Input.Password
                placeholder={t('Enter your new password')}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                autoComplete={'new-password'}
                iconRender={() => null}
              />
            </Form.Item>
            </>
            }
          </div>
        </>
      }
    </Form>
  )
})

export default UserInfoForm
