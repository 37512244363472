import translation_en from './en.js'
import translation_zh from './zh.js'

export default {
  en: {
    translation: translation_en,
  },
  zh: {
    translation: translation_zh,
  },
}
