import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import successImg from '@/assets/img/success.png'

const defaultProps = {
  className: '',
  title1: '',
  title2: '',
  des: '',
  tip: '',
  onClose: () => {},
  isShowBottom: true
};

export default function Success(props) {
  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  const location = useLocation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'o-success-mobile-container' : 'o-success-container'

  const handleContactUsClick = () => {
    const path = '/contactUs'
    if (location.pathname === path) {
      return options.onClose()
    }
    navigate(path)
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      <div className={'o-success-img-container'}>
        <img src={successImg} alt="" />
      </div>
      <div className={'o-success-content-container'}>
        {
          (options.title1 || options.title2) &&
          <div className={'o-success-content-title-container'}>
            {
              options.title1 &&
              <div>{options.title1}</div>
            }
            {
              options.title2 &&
              <div>{options.title2}</div>
            }
          </div>
        }
        {
          options.des &&
          <div className={'o-success-content-des-container'}>
            {options.des}
          </div>
        }
        {
          options.tip &&
          <div className={'o-success-content-tip-container'}>
            {options.tip}
          </div>
        }
      </div>
      {
        options.isShowBottom &&
        <div className={'o-success-bottom-container'}>
          <div className={'o-success-bottom-btn-container'} onClick={options.onClose}>close</div>
          <div className={'o-success-bottom-tip-container'}>
            Need Help?&nbsp;
            <span onClick={handleContactUsClick}>Contact Us</span>
          </div>
        </div>
      }
    </div>
  )
}
