/**
 * js正则表达式库--Regs.js
 * by denghao.me
 * @date 2017-02-11 18:09:35
 * @用法:  Regs.en.test('abc')  ->  true
 */

const Regs = {

  /*
   * 数字相关
   */
  //正数（可含小数、0）
  posiNum: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,

  //正整数
  posiInt: /^[1-9]\d*$/,

  //0或正整数
  posiInt0: /^(0|[1-9][0-9]*)$/,

  //2位的数字
  numLen2: /^[0-9]{2}$/,

  // 2-3位的数字：
  numLen2or3: /^\d{1,3}$/,

  // 非零开头的最多带两位小数的正数：
  posiFloat2: /^([1-9][0-9]*)+(\.[0-9]{1,2})?$/,

  //钱数（非零开头，1至8位）
  money: /^[1-9][0-9]{0,7}$/,

  // 带1-2位小数的正数或负数：
  float1or2: /^(-)?\d+(.\d{1,2})?$/,

  // 非零的负整数：
  negativeInt: /^-[1-9]\d*$/,

  // 负数或0：
  negative0: /^-[1-9]\d*|0$/,

  // 负整数或0：
  negativeInt0: /^(-[1-9]\d*|0)$/,

  // 负浮点数：
  negativeFloat: /^-([1-9]\d*\.\d*|0\.\d*[1-9]\d*)$/,

  // 正浮点数：
  posiFloat: /^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$/,

  // 浮点数：
  float: /^(-?\d+)(\.\d+)?$/,


  /*
   * 字符相关
   */

  // 英文和数字：
  numEn: /^[A-Za-z0-9]+$/,

  // 长度为2-4的所有字符：
  en2to4: /^.{2,4}$/,

  // 英文字母：
  En: /^[A-Za-z]+$/,

  // 大写英文字母：
  EN: /^[A-Z]+$/,

  // 小写英文字母：
  en: /^[a-z]+$/,

  // 小写英文字母：
  cn: /^[\u4E00-\u9FA5A]+$/,

  // 数字、字母、下划线
  numEn_: /^\w+$/,

  // 中文、英文、数字、下划线：
  numEnCn_: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/,

  // 中文、英文、数字：
  numEnCn: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,

  // 含有^%&',;=?$\”等字符：
  illegal: /[^%&',;=?$\x22]+/,

  // Email地址（最严谨）
  email: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,

  // 密码（8-20位，并且必须为大写英文、小写英文和数字）
  password: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,20}$/,

  // 验证码（6位数字）
  numLen6: /^[0-9]{6}$/,

  // 护照：
  passport: /^1[45][0-9]{7}|G[0-9]{8}|P[0-9]{7}|S[0-9]{7,8}|D[0-9]+$/,

  // 关于手机号码：
  // mobile: /^1[345789]\d{9}$/,
  hasNum: /\d/,
  mobile1: /^[0-9]{1}$/,
  mobile2: /^[0-9]{2}$/,
  mobile3: /^[0-9]{3}$/,
  mobile4: /^[0-9]{4}$/,
  mobile5: /^[0-9]{5}$/,
  mobile6: /^[0-9]{6}$/,
  mobile7: /^[0-9]{7}$/,
  mobile8: /^[0-9]{8}$/,
  mobile9: /^[0-9]{9}$/,
  mobile: /^[0-9]{10}$/,

  // 座机号码:
  telephone: /0\d{2,3}-\d{5,9}/,

  // 座机或手机号:
  // teleMobile: /^((0\d{2,3}-\d{5,9})|(1[345789]\d{9}))$/,
  teleMobile: /^((0\d{2,3}-\d{5,9})|([0-9]{10}))$/,

  //Zip（5位数字）
  numLen5: /^[0-9]{5}$/,

  // 身份证号(15位、18位数字)：
  idCard: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0-2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0-2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,

  // 日期： 2017-1-1或2017/1/1
  date: /^\d{4}(-|\/)\d{1,2}(-|\/)\d{1,2}$/,

  // 时间： 10:00:00
  time: /^([01]?\d|2[0-3]):[0-5]?\d:[0-5]?\d$/,

  // 日期+时间： 2017-1-1 10:00:00
  datetime: /^\d{4}(-|\/)\d{1,2}(-|\/)\d{1,2}\s([01]?\d|2[0-3]):[0-5]?\d:[0-5]?\d$/,

  // 一年的12个月(01～09和1～12)：
  month: /^(0?[1-9]|1[0-2])$/,

  // 一个月的31天(01～09和1～31)：
  day: /^((0?[1-9])|([1-2][0-9])|30|31)$/,

  //腾讯QQ号：
  qq: /[1-9][0-9]{4,}/,

  //中国邮政编码：
  postcode: /[1-9]\d{5}(?!\d)/,

  //IP地址：
  ip: /\d+\.\d+\.\d+\.\d+/,

  //域名：
  url: /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*([?&]\w+=\w*)*/,

  //域名(http开头):
  urlHttp: /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/,

  // 车牌:
  licencePlate: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,

  //银行卡：
  bankCardNum: /^([1-9]{1})(\d{15}|\d{18})$/,

  // USA手机号 ()格式
  USAPhoneNum1: /^\([0-9]{3,3}\)[0-9]{3,3}-[0-9]{4,4}/,

  // 10位手机号
  USAPhoneNum2: /^\([0-9]{3,3}\)\s*[0-9]{3,3}-[0-9]{4,4}/,

  // 10位手机号
  USAPhoneNum3: /^\d{10}$/,

  //美国手机号格式（14位）
  phoneNumberUSA: /^\(([0-9]{3}\)\s[0-9]{3}-[0-9]{4})$/,
}

export default Regs
