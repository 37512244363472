import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {useSelector} from 'react-redux';
import {Modal} from 'antd'

import './index.less'
import './mobile.less'

import OSuccess from '@/components/OSuccess'
import {getUsingClassName} from '@/common';

const defaultProps = {
  className: '',
  onModalCancel: () => {},
};

const OrderSuccess = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'order-success-mobile-container' : 'order-success-container'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    options.onModalCancel()
  };
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
    showModal
  }))
  
  return (
    <Modal
      className={className}
      maskClosable={false}
      keyboard={false}
      closeIcon={null}
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      <OSuccess
        title1={'Thank you for your online order! We are thrilled to receive your request and appreciate your business. Our team is now working diligently to process your order and ensure that it is prepared with the utmost care.'}
        des={'We will notify you as soon as your order is ready for shipment. . If you have any questions or need further assistance, our customer support team is always here to help.'}
        onClose={handleCancel}
      />
    </Modal>
  )
})

export default OrderSuccess
