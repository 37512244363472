import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'

export default function PrivacyPolicy() {
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'privacy-policy-mobile-container' : 'privacy-policy-container'
  
  return (
    <Layout
      isDetailPage={true}
      detailPageTitle={t('Privacy Policy')}>
      <div className={rootClassName}>
        <ContentHeader
          className={'home-header-container'}
          des={t('Privacy Policy')}
          title={t('OLONG RESTAURANT SUPPLIES')}
          title2={t('PRIVACY POLICY')}
        />
        <div className={'privacy-policy-content-container'}>
          <div>
            This Privacy Policy outlines how [Your Company Name] collects, uses, maintains, and discloses information collected from users (referred to as "you" or "users") of our website, mobile application, or other digital services (collectively, the "Service"). We are committed to safeguarding your privacy and ensuring the security of your personal information. By using our Service, you consent to the terms and practices described in this Privacy Policy.
          </div>
          <div>
            <div className={'privacy-policy-content-list-item-container'}>
              <span>1. </span>
              Information We Collect: We may collect personal identification information from users in various ways, including when users visit our site, register on the site, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or interact with our Service in any other way. The information we collect may include your name, email address, mailing address, phone number, and any other information you voluntarily provide.
            </div>
            <div>
              <div className={'privacy-policy-content-list-item-container'}>
                <span>2. </span>
                How We Use Collected Information: We may collect and use users' personal information for the following purposes:
              </div>
              <div className={'privacy-policy-content-list-2-container'}>
                <div>
                  To personalize user experience: We may use the information to understand how our users interact with our Service and customize it accordingly.
                </div>
                <div>
                  To improve our Service: Feedback provided by users helps us enhance our products and services.
                </div>
                <div>
                  To send periodic emails: We may use the email address provided to send information and updates related to user orders or inquiries. It may also be used to respond to their questions, requests, or other inquiries.
                </div>
              </div>
            </div>
            <div className={'privacy-policy-content-list-item-container'}>
              <span>3. </span>
              How We Protect Your Information: We adopt appropriate data collection, storage, and processing practices, as well as security measures, to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Service.
            </div>
            <div className={'privacy-policy-content-list-item-container'}>
              <span>4. </span>
              Sharing Your Personal Information: We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
            </div>
            <div className={'privacy-policy-content-list-item-container'}>
              <span>5. </span>
              Compliance with Legal Requirements: We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
            </div>
            <div className={'privacy-policy-content-list-item-container'}>
              <span>6. </span>
              Changes to This Privacy Policy: We reserve the right to update or revise this Privacy Policy at any time. We encourage users to check this page frequently to stay informed about how we are protecting the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of any modifications.
            </div>
            <div className={'privacy-policy-content-list-item-container'}>
              <span>7. </span>
              Your Acceptance of These Terms: By using our Service, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our Service. Your continued use of the Service following the posting of changes to this policy will be deemed your acceptance of those changes.
            </div>
          </div>
          <div>If you have any questions or concerns about this Privacy Policy, please contact us at [contact email].</div>
          <div>This Privacy Policy was last updated on [date].</div>
        </div>
      </div>
    </Layout>
  )
}
