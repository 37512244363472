import {Navigate, createBrowserRouter} from 'react-router-dom';

import Home from '@/pages/Home'
import OurProducts from '@/pages/OurProducts'
import ProductDetail from '@/pages/ProductDetail'
import ContactUs from '@/pages/ContactUs'
import SignupLogin from '@/pages/SignupLogin'
import ShoppingCart from '@/pages/ShoppingCart'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import TermsOfService from '@/pages/TermsOfService'
import MyProfile from '@/pages/MyProfile'
import EditOrder from '@/pages/EditOrder'
import EditOrderProducts from '@/pages/EditOrderProducts'
import EditOrderProductDetail from "@/pages/EditOrderProductDetail";
import Search from "@/pages/Search"

const router = createBrowserRouter([
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/ourProducts',
    element: <OurProducts />,
  },
  {
    path: '/ourProducts/:group',
    element: <OurProducts />,
  },
  {
    path: '/ourProducts/:group/:category',
    element: <OurProducts />,
  },
  {
    path: '/productDetail/:id',
    element: <ProductDetail />,
  },
  {
    path: '/contactUs',
    element: <ContactUs />,
  },
  {
    path: '/signupLogin',
    element: <SignupLogin />,
  },
  {
    path: '/shoppingCart',
    element: <ShoppingCart />,
  },
  {
    path: '/privacyPolicy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/termsOfService',
    element: <TermsOfService />,
  },
  {
    path: '/myProfile',
    element: <MyProfile />,
  },
  {
    path: '/editOrder/:id',
    element: <EditOrder />,
  },
  {
    path: '/editOrderProducts',
    element: <EditOrderProducts />,
  },
  {
    path: '/editOrderProducts/:group',
    element: <EditOrderProducts />,
  },
  {
    path: '/editOrderProducts/:group/:category',
    element: <EditOrderProducts />,
  },
  {
    path: '/editOrderProductDetail/:id',
    element: <EditOrderProductDetail />,
  },
  {
    path: '/search',
    element: <Search />,
  },
  {
    path: '*',
    element: <Navigate to={'/home'} replace={true} />
  },
]);

export default router
