import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Checkbox} from 'antd'
import {LeftOutlined} from '@ant-design/icons';

import './index.less'
import './mobile.less'

import UserInfoForm from '@/components/UserInfoForm'

import {getUsingClassName} from '@/common';
import {addAddressToAddressList, updateAddressItem} from '@/store/modules/address.slice';
import {TOOLKIT_ASYNC_THUNK_REJECTED_KEY} from '@/constants';

const defaultProps = {
  className: '',
  onBack: () => {},
  editInfo: null
};

export default function MyAddressDetail(props) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-address-detail-mobile-container' : 'my-address-detail-container'
  const {userInfo} = commonState
  
  const {editInfo} = options

  const initialValues = useMemo(() => {
    return {
      address: editInfo?.Street,
      city: editInfo?.City,
      state: editInfo?.State,
      zipCode: editInfo?.Zip,
    }
  }, [editInfo])
  
  const userInfoFormRef = useRef(null);
  
  const [isDefaultAddress, setIsDefaultAddress] = useState(true)
  const handleIsDefaultAddressChange = event => {
    setIsDefaultAddress(event.target.checked)
  }
  useEffect(() => {
    setIsDefaultAddress(editInfo?.IsDefault)
  }, [editInfo?.IsDefault])
  const resetFields = () => userInfoFormRef.current?.resetFields()
  
  const handleAddAddress = (values) => {
    const address = {
      Street: values.address,
      City: values.city,
      State: values.state,
      Zip: values.zipCode,
      Country: userInfo.Country,
      FirstName: userInfo.FirstName,
      LastName: userInfo.LastName,
      Telephone: userInfo.Telephone,
      IsDefault: isDefaultAddress
    };
    dispatch(addAddressToAddressList({address}))
      .then(({meta}) => {
        if (meta.requestStatus === TOOLKIT_ASYNC_THUNK_REJECTED_KEY) {
          return
        }
        resetFields()
      })
  }
  const handleEditAddress = (values) => {
    const address = {
      Street: values.address,
      City: values.city,
      State: values.state,
      Zip: values.zipCode,
      Country: editInfo.Country,
      FirstName: editInfo.FirstName,
      LastName: editInfo.LastName,
      Telephone: editInfo.Telephone,
      IsDefault: isDefaultAddress
    };
    dispatch(updateAddressItem({address, customerDetailId: editInfo.CustomerDetail_id}))
  }
  const onFormFinish = (values) => {
    if (!editInfo) {
      return handleAddAddress(values)
    }
    handleEditAddress(values)
  }
  const handleSaveClick = () => {
    userInfoFormRef.current?.submit()
  }

  useEffect(() => {
    resetFields()
  }, [initialValues])

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      <div className={'my-address-detail-top-container'} onClick={options.onBack}>
        <LeftOutlined />
        <div className={'my-address-detail-top-title'}>{t('Address Detail')}</div>
      </div>
      <UserInfoForm
        ref={userInfoFormRef}
        isShowBaseInfo={false}
        isShowCompanyInfo={false}
        isShowAddressInfo={true}
        isShowPassword={false}
        onFormFinish={onFormFinish}
        initialValues={initialValues}
      />
      <Checkbox
        className={'my-address-detail-set-default-checkbox-container'}
        checked={isDefaultAddress}
        onChange={handleIsDefaultAddressChange}>
        {t('Set as default address')}
      </Checkbox>
      <div
        className={'my-address-detail-btn'}
        onClick={handleSaveClick}>
        {t('Save')}
      </div>
    </div>
  )
}
