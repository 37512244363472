import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Spin, FloatButton} from 'antd';

import './index.less'

import router from '@/router'

import {getToken} from '@/common';
import eventBus from '@/common/EventBus'
import {
  fetchProductGroup,
  fetchUserInfo,
  logout,
  setIsLogged,
  startLoading,
  stopLoading
} from '@/store/modules/common.slice';
import {fetchOrderCart} from '@/store/modules/cart.slice';
import {LOGOUT_EVENT_NAME, START_LOADING_EVENT_NAME, STOP_LOADING_EVENT_NAME} from '@/constants';

function App() {
  const dispatch = useDispatch()

  const commonState = useSelector(state => state.common)
  const loading = commonState.loading
  
  const isLogged = !!getToken()
  const startLoadingEventFun = () => {
    if (loading) {
      return
    }
    dispatch(startLoading())
  }
  const stopLoadingEventFun = () => {
    dispatch(stopLoading())
  }
  const logoutEventFun = () => {
    dispatch(logout())
  }
  useEffect(() => {
    dispatch(fetchProductGroup())
    dispatch(setIsLogged(isLogged))
    if (isLogged) {
      dispatch(fetchUserInfo())
      dispatch(fetchOrderCart())
    }
    
    eventBus.addEventListener(START_LOADING_EVENT_NAME, startLoadingEventFun)
    eventBus.addEventListener(STOP_LOADING_EVENT_NAME, stopLoadingEventFun)
    eventBus.addEventListener(LOGOUT_EVENT_NAME, logoutEventFun)
    
    return () => {
      eventBus.removeEventListener(START_LOADING_EVENT_NAME, startLoadingEventFun)
      eventBus.removeEventListener(STOP_LOADING_EVENT_NAME, stopLoadingEventFun)
      eventBus.removeEventListener(LOGOUT_EVENT_NAME, logoutEventFun)
    }
  }, [])
  
  const renderRoute = (item, index) => <Route {...item} key={'route_' + index} />
  const renderRoutes = () => <Routes>
    {router.routes.map((item, index) => renderRoute(item, index))}
  </Routes>
  
  return (
    <BrowserRouter>
      <div className="app">
        {renderRoutes()}
        <FloatButton.BackTop />
      </div>
      {
        loading && <Spin
          rootClassName="spin-root-class"
          size={'large'}
        />
      }
    </BrowserRouter>
  );
}

export default App;
