import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'

import resources from './locales/resources'
import {LANGUAGE_ENGLISH_KEY} from '@/constants'
import {getLanguage} from '@/common';

i18next
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGE_ENGLISH_KEY,
    lng: getLanguage(),
    resources,
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
