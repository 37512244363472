export const HTTP_POST = 'post';
export const HTTP_GET = 'get';
export const HTTP_PUT = 'put';
export const HTTP_DELETE = 'delete';
export const SIMPLE_POST = 'application/x-www-form-urlencoded;charset=UTF-8';
export const JSON_POST = 'application/json;charset=UTF-8';
export const FORM_DATA = 'multipart/form-data';
export const CONTENT_TYPE = "Content-Type";

export const BASE_URL= 'https://olong-scm.truckxi.com';
export const TOKEN_KEY= 'token';
export const SHIPPING_ADDRESS_KEY= 'Shipping';
export const USER_ZIPCODE_KEY= 'zipcode';
export const REDIRECT_KEY= 'redirect';
export const START_LOADING_EVENT_NAME = 'startLoadingEvent'
export const STOP_LOADING_EVENT_NAME = 'stopLoadingEvent'
export const LOGOUT_EVENT_NAME = 'logoutEvent'
export const LOCATION_LIST = [
  {
    name: 'LA',
    zipcode: '11354',
    companyId: '1',
    pickupWarehouseIdId: '1'
  }
]
export const DEFAULT_LOCATION = LOCATION_LIST[0]
export const DEFAULT_ZIPCODE = DEFAULT_LOCATION.zipcode

export const MY_PROFILE_INFORMATION_KEY = 'MY_PROFILE_INFORMATION'
export const MY_ADDRESS_KEY = 'MY_ADDRESS'
export const MY_LIST_KEY = 'MY_LIST'
export const MY_ORDER_KEY = 'MY_ORDER'
export const MY_QRCODE_KEY = 'MY_QRCODE'
export const MY_SUBORDINATE_KEY = 'MY_SUBORDINATE'
export const ACCOUNT_DELETE = 'ACCOUNT_CANCELLATION'
export const EDIT_ORDER_KEY = 'EDIT_ORDER'
export const MY_PROFILE_PATH_KEY = 'pathKey'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const PICK_UP_KEY = 'Pickup'
export const DELIVERY_KEY = 'Delivery'
export const PRODUCT_ITEM_BTN_TYPE_1 = 'ProductItemBtnType1'
export const PRODUCT_ITEM_BTN_TYPE_2 = 'ProductItemBtnType2'
export const PRODUCT_ITEM_BTN_TYPE_3 = 'ProductItemBtnType3'
export const TOOLKIT_ASYNC_THUNK_REJECTED_KEY = 'rejected'
export const DATE_FORMAT_2 = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const DATE_FORMAT_3 = 'YYYY-MM-DD'
export const DATE_FORMAT_4 = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'

export const LANGUAGE_ENGLISH_KEY = 'en'
export const LANGUAGE_CHINESE_KEY = 'zh'
export const STORAGE_LANGUAGE_KEY = 'LANGUAGE_KEY'
export const PRODUCT_DEFAULT_LIMIT = 12
export const FACEBOOK_LINK = 'https://www.facebook.com/OTI6630'
export const EDIT_ORDER_PRODUCT_LIST_KEY = 'editOrderProductList'
export const EDIT_ORDER_IS_GET_ORDER_DETAILS_KEY = 'editOrderIsGetOrderDetails'
