import React, { useEffect, useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import './index.less';

const SkuListView = (props) => {
    const [defaultSelectKey, setDefaultKey] = useState([]);
    const [defaultSelectValue, setDefaultValue] = useState('');
    const [items, setItems] = useState([])
    
    useEffect(()=>{  // 初始数据     
       if(props.data) {
        let items = []; 
        let main0 = {...props.data, related_products:[]}; // 外层数据提取 排除related_products
        if(props.data.related_products && props.data.related_products.length > 0) {
            let isHave = false;
            items = props.data.related_products.map(item => {
                if(item.ProductNum == main0.ProductNum) {
                    isHave = true;
                }
                return {
                    label: <span style={{color:'#6fa8a6'}} onClick={()=>{handleChangeSku(item)}}>{item.PackageSize}</span>,
                    key: item.ProductNum,
                    disabled: (!item.IsOverSaleable && item.StockLevel <= 0)
                }
            })
            
            if(!isHave) {// 关联数据中没有外层数据，添加进去，有就不添加
                items.unshift({
                    label: <span onClick={()=>{handleChangeSku(main0)}}>{main0.PackageSize}</span>,
                    key: main0.ProductNum,
                    disabled: (!main0.IsOverSaleable && main0.StockLevel <= 0)
                })
                setDefaultKey([main0.ProductNum])
                setDefaultValue(main0.PackageSize)
            }
            setItems(items);
        } else {
            if(items.length > 1) {
                setDefaultKey([main0.ProductNum])
            }
            setDefaultValue(main0.PackageSize)
        }
       }
    }, [props.data])

    const handleChangeSku = (item) => {
        if(defaultSelectKey.length > 0 && defaultSelectKey[0] && item.ProductNum == defaultSelectKey[0]) {
            return
        }
        setDefaultKey([item.ProductNum])
        setDefaultValue(item.PackageSize)
        props.changeSku({...item, related_products: []});
    }

    return(
        <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: defaultSelectKey,
        }}
        trigger={['click']}
      >
          <Space style={{color:'#6fa8a6'}}>
            {defaultSelectValue}
            {items && items.length > 1 && <DownOutlined />}
          </Space>
      </Dropdown>
    )
}

export default SkuListView;

