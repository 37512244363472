import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Empty, Popconfirm} from 'antd';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import {
  fetchSubCustomer,
  deactiveSubCustomerItem, activeSubCustomerItem
} from '@/store/modules/subordinate.slice';

import MySubordinateDetail from '@/components/MySubordinateDetail';

const defaultProps = {
  className: '',
  layoutRestoreScrollState: () => {},
};

export default function MySubordinate(props) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const commonState = useSelector(state => state.common)
  const subordinateState = useSelector(state => state.subordinate)
  const rootClassName = commonState.isMobile ? 'my-subordinate-mobile-container' : 'my-subordinate-container'

  const {subCustomer} = subordinateState

  const [isDetailPage, setIsDetailPage] = useState(false)
  const [detailPageEditItem, setDetailPageEditItem] = useState(null)
  
  useEffect(() => {
    dispatch(fetchSubCustomer())
  }, [])

  const handleAddClick = () => {
    setDetailPageEditItem(null)
    setIsDetailPage(true)
  }
  const handleEditClick = item => {
    setDetailPageEditItem(item)
    setIsDetailPage(true)
  }
  const handleDeactiveClick = item => {
    dispatch(deactiveSubCustomerItem({subMobileId: item.MobileUser_id}))
  }
  const handleActiveClick = item => {
    dispatch(activeSubCustomerItem({subMobileId: item.MobileUser_id}))
  }

  useEffect(() => {
    options.layoutRestoreScrollState()
  }, [isDetailPage])

  const renderItem = (item, index) => <div
    className={'my-subordinate-list-item-container'}
    key={`sub_customer_${index}`}>
    <div className={'my-subordinate-list-item-content'}>
      <div className={'my-subordinate-list-item-label'}>{t('Name')}</div>
      <div className={'my-subordinate-list-item-value'}>{item.name}</div>
    </div>
    <div className={'my-subordinate-list-item-content'}>
      <div className={'my-subordinate-list-item-label'}>{t('Email Address')}</div>
      <div className={'my-subordinate-list-item-value'}>{item.email}</div>
    </div>
    <div className={'my-subordinate-list-item-content'}>
      <div className={'my-subordinate-list-item-label'}>{t('Phone number')}</div>
      <div className={'my-subordinate-list-item-value'}>{item.phone}</div>
    </div>
    <div className={'my-subordinate-list-item-bottom-container'}>
      {item.IsActive 
      ? <Popconfirm
          title={t('Warning')}
          description={t('Confirm to deactivate the subordinate?')}
          okText={t('Yes')}
          cancelText={t('No')}
          onConfirm={() => handleDeactiveClick(item)}>
          <div className={'my-subordinate-list-item-bottom-deactive-btn'}>
            {t('Deactive')}
          </div>
        </Popconfirm> 
      : <Popconfirm
          title={t('Warning')}
          description={t('Confirm to activate the subordinate?')}
          okText={t('Yes')}
          cancelText={t('No')}
          onConfirm={() => handleActiveClick(item)}>
        <div className={'my-subordinate-list-item-bottom-active-btn'}>
          {t('Active')}
        </div>
      </Popconfirm>}
    </div>
  </div>

  const handleDetailBackClick = () => {
    setIsDetailPage(false)
  }

  const renderDefaultPage = () => <div
    className={'my-subordinate-default-page-container'}>
    {
      !subCustomer.length &&
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
    <div className={'my-subordinate-list-container'}>
      {subCustomer.map((item, index) => renderItem(item, index))}
    </div>
    <div
      className={'my-subordinate-btn'}
      onClick={handleAddClick}>
      {t('Add')}
    </div>
  </div>
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      {
        !isDetailPage && renderDefaultPage()
      }
      {
        isDetailPage &&
        <MySubordinateDetail
          onBack={handleDetailBackClick}
          editInfo={detailPageEditItem}
        />
      }
    </div>
  )
}
