import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next'
import {Form, Input, message, Modal} from 'antd';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import {reqResetPassword, resetPassword, verificationCodeActive} from '@/api/req-api';
import OSuccess from '@/components/OSuccess';

const STEP_1_KEY = 'step1';
const STEP_2_KEY = 'step2';
const STEP_3_KEY = 'step3';
const STEP_4_KEY = 'step4';

const defaultProps = {
  className: '',
};

const ForgetPassword = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'forget-password-mobile-container' : 'forget-password-container'
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    step1ResetFields()
    step2ResetFields()
    step3ResetFields()
    setStep(STEP_1_KEY)
    setIsModalOpen(false);
  };
  
  const [token, setToken] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  
  const [step, setStep] = useState(STEP_1_KEY)
  const step1FormRef = useRef(null);
  const step2FormRef = useRef(null);
  const step3FormRef = useRef(null);
  
  const handleSendCodeClick = () => {
    step1FormRef.current?.submit()
  }
  const step1ResetFields = () => step1FormRef.current?.resetFields()
  const onStep1FormFinish = values => {
    reqResetPassword(values.name).then(res => {
      setToken(res.data)
      setStep(STEP_2_KEY)
    })
  }
  const handleConfirmClick = () => {
    step2FormRef.current?.submit()
  }
  const step2ResetFields = () => step2FormRef.current?.resetFields()
  const onStep2FormFinish = values => {
    const {verificationCode} = values
    setVerificationCode(verificationCode)
    verificationCodeActive(verificationCode, token).then(res => {
      setToken(res.data)
      setStep(STEP_3_KEY)
    })
  }
  const handleConfirmResetClick = () => {
    step3FormRef.current?.submit()
  }
  const step3ResetFields = () => step3FormRef.current?.resetFields()
  const onStep3FormFinish = values => {
    const {newPassword, reenterPassword} = values
    if (newPassword !== reenterPassword) {
      return message.error('Passwords do not match')
    }
    const params = {
      new_password: values.newPassword,
      token,
      code: verificationCode
    }
    resetPassword(params).then(res => {
      setStep(STEP_4_KEY)
    })
  }
  
  const handleLoginClick = () => {
    handleCancel()
  }

  const handleCancelClick = () => {
    handleCancel()
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  
  const renderStep1 = () => {
    return <>
      <div className={'forget-password-tip'}>
        {t('Please enter your email address or phone Number. You will receive a verification code to create a new password.')}
      </div>
      <div className={'forget-password-spacing'}></div>
      <Form
        className={'forget-password-form-container'}
        ref={step1FormRef}
        layout={'vertical'}
        requiredMark={false}
        onFinish={onStep1FormFinish}
        autoComplete="off">
        <Form.Item
          label={t('Email address or Phone Number')}
          name="name"
          rules={[
            {
              required: true,
              message: t('Please enter your email address or phone Number'),
            },
          ]}>
          <Input
            placeholder={t('Enter your email address or phone Number')}
          />
        </Form.Item>
      </Form>
      <div className={'forget-password-btn'} onClick={handleSendCodeClick}>
        {t('Send Code')}
      </div>
    </>
  }
  const renderStep2 = () => {
    return <>
      <div className={'forget-password-tip'}>
        {t('A verification code has been sent to the email or phone. Please input to continue.')}
      </div>
      <div className={'forget-password-spacing'}></div>
      <Form
        className={'forget-password-form-container'}
        ref={step2FormRef}
        layout={'vertical'}
        requiredMark={false}
        onFinish={onStep2FormFinish}
        autoComplete="off">
        <Form.Item
          label={t('Verification Code')}
          name="verificationCode"
          rules={[
            {
              required: true,
              message: t('Please enter your verification code',)
            },
          ]}>
          <Input
            placeholder={t('Enter your verification code')}
          />
        </Form.Item>
      </Form>
      <div className={'forget-password-btn'} onClick={handleConfirmClick}>
        {t('Confirm')}
      </div>
    </>
  }
  const renderStep3 = () => {
    return <>
      <div className={'forget-password-tip'}>
        {t('Please enter a new password.')}
      </div>
      <div className={'forget-password-spacing'}></div>
      <Form
        className={'forget-password-form-container'}
        ref={step3FormRef}
        layout={'vertical'}
        requiredMark={false}
        onFinish={onStep3FormFinish}
        autoComplete="off">
        <Form.Item
          label={t('New Password')}
          name="newPassword"
          rules={[
            {
              required: true,
              message: t('Please enter your new password'),
            },
          ]}>
          <Input
            placeholder={t('Enter your new password')}
          />
        </Form.Item>
        <Form.Item
          label={t('Reenter Password')}
          name="reenterPassword"
          rules={[
            {
              required: true,
              message: t('Please enter your reenter password'),
            },
          ]}>
          <Input
            placeholder={t('Enter your reenter password')}
          />
        </Form.Item>
      </Form>
      <div className={'forget-password-btn'} onClick={handleConfirmResetClick}>
        {t('Confirm reset')}
      </div>
    </>
  }
  const renderStep4 = () => {
    return <>
      <OSuccess
        title1={t('Password successfully reset!')}
        title2={t('You may now log in with your new password.')}
        isShowBottom={false}
      />
      <div className={'forget-password-spacing'}></div>
      <div className={'forget-password-btn'} onClick={handleLoginClick}>
        {t('Log In')}
      </div>
    </>
  }
  
  return (
    <Modal
      className={className}
      maskClosable={false}
      keyboard={false}
      closeIcon={null}
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      {
        step !== STEP_4_KEY &&
        <div className={'forget-password-title'}>{t('Reset Password')}</div>
      }
      <div className={'forget-password-content-container'}>
        {step === STEP_1_KEY && renderStep1()}
        {step === STEP_2_KEY && renderStep2()}
        {step === STEP_3_KEY && renderStep3()}
        {step === STEP_4_KEY && renderStep4()}
      </div>
      {
        step !== STEP_4_KEY &&
        <>
          <div className={'forget-password-spacing'}></div>
          <div
            className={'forget-password-btn2'}
            onClick={handleCancelClick}>
            {t('Close')}
          </div>
        </>
      }
    </Modal>
  )
})

export default ForgetPassword
